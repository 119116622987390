//		Réglages globaux

@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

section.block {
  margin-bottom: 80px;
}

section.block-slider {
  margin-bottom: 0;

  .slide {
    position: relative;
    height: 725px;

    @media (max-height: 900px) {
      height: 600px;
    }

    .full-image {
      position: absolute;
      width: 100%;
      height: 685px;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover;';

      @media (max-height: 900px) {
        height: 560px;
      }
    }

    > .container {
      height: 100%;

      .black-block {
        width: calc(50% - 30px);
        position: absolute;
        bottom: 0;
        left: 15px;
        padding: 35px 50px;
        @include background-opacity($black, .85);

        @include media-breakpoint-down(lg) {
          width: calc(80% - 30px);
        }

        @include media-breakpoint-down(md) {
          width: calc(90% - 30px);
        }

        @include media-breakpoint-down(sm) {
          width: calc(100% - 30px);
          padding: 25px;
        }

        .title {
          color: $white;
          font-size: 40px;
          font-weight: 800;

          @include media-breakpoint-down(sm) {
            font-size: 34px;
          }
        }

        .subtitle {
          color: #eaeaea;
          font-size: 30px;
          font-weight: bold;

          @include media-breakpoint-down(sm) {
            font-size: 26px;
          }
        }

        .text {
          padding-top: 15px;
          padding-bottom: 20px;
          color: #eaeaea;
          line-height: 28px;
        }
      }
    }
  }

  .owl-dots {
    position: absolute;
    bottom: 0px;
    right: 10px;

    .owl-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 3px;
      border: 1px solid $grey;
      border-radius: 50%;

      &:hover, &:focus, &:active, &.active {
        background: $green;
        border: 1px solid $green;
      }
    }
  }
}

section.block-image-text-50-50 {
  .image-wrapper {
    width: 100%;
    height: 420px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover;';
    }
  }

  .content-wrapper {
    @include media-breakpoint-up(lg) {
      padding-left: 40px;
    }

    .title {
      padding-left: 20px;
      font-size: 30px;
      line-height: 38px;
      border-left: 4px solid $green;
      hyphens: auto;

      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
    }

    .text {
      padding-top: 30px;
      padding-bottom: 30px;
      color: $grey;

      strong, b {
        color: $brown;
      }
    }
  }
}

section.block-image-text-fleeing-edge {
  background: $light-grey;

  .image-part {
    .image-wrapper {
      position: relative;
      width: 100%;
      min-height: 420px;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-family: 'object-fit: cover;';
      }
    }

    &.order-1 {
      @include media-breakpoint-up(lg) {
        &:before {
          content: "";
          width: 9999px;
          height: 100%;
          background: #fff;
          display: block;
          position: absolute;
          top: 0;
          left: -9984px;
        }

        .image-wrapper {
          padding-right: 40px;
        }
      }
    }

    &.order-2 {
      @include media-breakpoint-up(lg) {
        &:after {
          content: "";
          width: 9999px;
          height: 100%;
          background: #fff;
          display: block;
          position: absolute;
          top: 0;
          right: -9984px;
        }

        .image-wrapper {
          padding-left: 40px;
        }
      }
    }
  }

  .content-part {
    .content-wrapper {
      padding-top: 80px;
      padding-bottom: 80px;

      @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .title {
        padding-left: 20px;
        font-size: 30px;
        line-height: 38px;
        border-left: 4px solid $green;
        hyphens: auto;
      }

      .text {
        padding-top: 30px;
        padding-bottom: 30px;
        color: $brown;

        strong, b {
          color: $brown;
        }
      }
    }
  }
}

section.block-slider-product-categories {
  .block-title {
    margin-bottom: 55px;
    text-align: center;
    font-size: 26px;

    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 60px;
      margin: auto;
      margin-top: 8px;
      background: $green;
    }
  }

  .owl-stage {
    margin: auto;

    .owl-item {
      .product-category {
        padding: 20px;
        text-align: center;

        .image-wrapper {
          width: 100%;
          height: 400px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover;';
          }
        }

        .title {
          min-height: 26px;
          margin-top: 20px;
          line-height: 26px;
          text-transform: uppercase;
          font-weight: bold;
        }

        .subtitle {
          color: $grey;
          line-height: 26px;
        }

        .discover {
          color: $green;
          line-height: 34px;

          .picto {
            display: inline-block;
            width: auto;
            margin-left: 5px;
            height: 12px;
            @include transition;
          }

          &:hover, &:active, &:focus {
            text-decoration: none;

            .picto {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .owl-dots {
    text-align: center;
    margin: auto;

    .owl-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 3px;
      border: 1px solid $grey;
      border-radius: 50%;

      &:hover, &:focus, &:active, &.active {
        background: $green;
        border: 1px solid $green;
      }
    }
  }
}

section.block-slider-last-news {
  .block-title {
    margin-bottom: 55px;
    text-align: center;
    font-size: 26px;

    &:after {
      content: "";
      display: block;
      height: 4px;
      width: 60px;
      margin: auto;
      margin-top: 8px;
      background: $green;
    }
  }

  .owl-stage {
    margin: auto;

    .owl-item {
      .news {
        padding: 20px;

        .image-wrapper {
          width: 100%;
          height: 210px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover;';
          }
        }

        .title {
          margin-top: 12px;
          margin-bottom: 12px;
          font-size: 22px;
          line-height: 26px;
          font-family: "Open Sans";
          font-weight: bold;
          color: $brown;
        }

        .excerpt {
          height: 66px;
          margin-bottom: 25px;
          font-size: 14px;
          line-height: 22px;
          color: $grey;
          overflow: hidden;
        }
      }
    }
  }

  .owl-dots {
    text-align: center;
    margin: auto;

    .owl-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 3px;
      border: 1px solid $grey;
      border-radius: 50%;

      &:hover, &:focus, &:active, &.active {
        background: $green;
        border: 1px solid $green;
      }
    }
  }
}

section.block-complex-text {
  color: $brown;

  h1, h2, h3, h4, h5, h6 {
    color: $black;
  }

  h2 {
    font-size: 26px;
    line-height: 38px;
  }

  img, video, iframe {
    max-width: 100%;
    height: auto;
  }

  ul {
    margin-left: 40px;
    list-style-type: none;

    > li {
      text-indent: -5px;

      &:before {
        content: "-";
        margin-right: 5px;
        text-indent: -5px;
      }
    }
  }
}

section.block-introduction {
  color: $brown;
  text-align: center;

  h1, h2, h3, h4, h5, h6 {
    color: $black;
  }

  h2 {
    font-size: 26px;
    line-height: 38px;
  }
}