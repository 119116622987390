//		Réglages globaux

@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

// Add font

*,
*::after,
*::before {
  outline: none;
  position: relative;
  -webkit-tap-highlight-color: none;
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
  @include antialiased;
}


main {
  position: relative;
  overflow-x: hidden;
  min-height: 600px;
  //margin-top: 193px;
  z-index: 1;
}

body {
  color: $black;
  font-family: $font-family, sans-serif;
  font-size: $font-size;
  line-height: $line-height;
  overflow-x: hidden;
}


p,
ul,
ol,
blockquote {
  margin-bottom: 0px;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Montserrat";
  font-weight: bold;
}

h1 {
  font-size: 30px;
  line-height: 38px;
  margin-top: 0;
}

h2 {
  font-size: 2.369em;
}

h3 {
  font-size: 1.777em;
}

h4 {
  font-size: 1.333em;
}

small {
  font-size: .75em;
}

sup {
  font-size: .75em;
  vertical-align: super;
}

a {
  color: inherit;
  @include transition;
}

p {
  margin-bottom: 0;
}


ul,
ol {
  margin-left: 1em;
  padding-left: 1em;
}

b,
strong {
  font-weight: bold;
}

#edittag {
  width: 100% !important;
}

#breadcrumb {
  a {
    color: $green;
  }
}