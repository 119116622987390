@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Buttons
\*------------------------------------*/

.btn {
  border-radius: 0 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  padding: 8px 30px !important;
  max-width: 100%;
  @include transition;
}

.big-btn {
  padding: 14px 40px !important;
  font-size: 15px !important;
  font-family: Montserrat !important;
}

.btn-green {
  background-color: $green !important;
  color: $white !important;
  border: 1px solid $green !important;

  &:hover, &:active, &:focus {
    background-color: $white !important;
    color: $green !important;
  }
}

.btn-border-green {
  background-color: $white !important;
  color: $green !important;
  border: 1px solid $green !important;

  &:hover, &:active, &:focus {
    background-color: $green !important;
    color: $white !important;
  }
}