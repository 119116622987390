@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Footer
\*------------------------------------*/

footer.footer {
  margin-top: 150px;

  @include media-breakpoint-down(sm) {
    margin-top: 80px;
  }

  #footer-top {
    background-color: $light-grey;
    padding-top: 70px;
    padding-bottom: 70px;

    #logo-footer {
      width: 200px;
      max-width: 100%;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }

    .nav-item {
      .nav-link {
        padding: 0;
        color: #a89e98;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 36px;

        &:hover, &:focus, &:active {
          text-decoration: underline;
        }
      }
    }

    .main-menu {
      .nav-item:last-child {
        display: none;
      }
    }

    .black-block {
      width: 100%;
      padding: 80px 25px 60px 25px;
      background: #3c3c3b;
      color: #a89e98;
      text-align: center;
      margin-top: 50px;

      @include media-breakpoint-up(lg) {
        margin-top: -150px;
      }

      .title {
        margin-bottom: 5px;
        font-size: 20px;
        line-height: 36px;
        font-weight: bold;
        color: $white;
      }

      .description {
        margin-bottom: 20px;
      }

      .btn-green {
        text-transform: uppercase;
      }
    }
  }

  #footer-bottom {
    padding: 25px 15px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #939292;

    .links {
      a {
        &:hover, &:focus, &:active {
          color: #939292;
        }
      }
    }
  }
}