@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Header
\*------------------------------------*/

header.header {
  position: relative;
  width: 100%;
  padding-top: 30px;
  z-index: 1000;
  background: $white;
  @include transition();

  @include media-breakpoint-down(md) {
    padding-top: 5px;
  }

  #logo {
    width: 275px;

    @include media-breakpoint-down(xl) {
      width: 200px;
    }

    @include media-breakpoint-down(lg) {
      width: 175px;
    }

    @include media-breakpoint-down(md) {
      height: 75px;
      width: auto;
    }
  }

  nav.navbar {
    padding: 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    > .container {
      align-items: flex-start !important;

      @include media-breakpoint-down(md) {
        align-items: center !important;
      }
    }

    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;

      @include media-breakpoint-down(md) {
        padding-bottom: 5px;
      }
    }

    .navbar-toggler {
      color: rgba(0,0,0,.5);
      border-color: rgba(0,0,0,.1);
      outline: 0;

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }

      &[aria-expanded="true"] {
        border-color: $green;

        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(149,193,31)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
      }
    }

    .navbar-collapse {
      padding-top: 20px;

      @include media-breakpoint-down(xl) {
        padding-top: 0px;
      }

      .navbar-nav {
        .nav-item {
          padding-left: 0;
          padding-right: 40px;

          @include media-breakpoint-down(lg) {
            padding-right: 20px;
          }

          &:last-child {
            padding-right: 0px !important;
          }

          .nav-link {
            padding: 0;
            color: $green;
            font-size: 14px;
            line-height: 36px;
            font-weight: 600;

            img {
              width: 19px;
              margin-right: 2px;
            }
          }
        }

        &.main-menu {
          border-bottom: 1px solid rgba(0, 0, 0, .1);

          @include media-breakpoint-down(md) {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .nav-item {
            &:last-child {
              .nav-link {
                font-size: 18px;
                font-weight: bold;
              }
            }
          }

          .nav-link {
            padding-bottom: 15px;

            &.active {
              &:after {
                position: absolute;
                content: "";
                display: block;
                height: 2px;
                width: 100%;
                bottom: 0;
                background: $green;
              }
            }

            @include media-breakpoint-down(md) {
              padding-bottom: 0px;
            }

            @include media-breakpoint-up(lg) {
              &:hover, &:active, &:focus {
                &:after {
                  position: absolute;
                  content: "";
                  display: block;
                  height: 2px;
                  width: 100%;
                  bottom: 0;
                  background: $green;
                }
              }
            }
          }
        }

        &.main-sub-menu {
          padding-top: 0;

          @include media-breakpoint-down(md) {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .nav-item {
            padding-left: 0;
            padding-right: 30px;
            padding-bottom: 30px;
            padding-top: 10px;

            @include media-breakpoint-down(lg) {
              padding-right: 20px;
            }

            @include media-breakpoint-down(md) {
              padding-top: 0px;
              padding-bottom: 0px;
            }

            .nav-link {
              font-weight: bold;
              text-transform: uppercase;
              color: $black;
              line-height: 50px;

              @include media-breakpoint-down(md) {
                line-height: 40px;
              }

              @include media-breakpoint-up(lg) {
                &:hover, &:active, &:focus {
                  color: $green;
                  text-decoration: none;
                }
              }

              i {
                margin-left: 5px;

                @include media-breakpoint-down(md) {
                  display: none;
                }
              }
            }

            &.active .nav-link {
              color: $green;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  #mega-menu {
    display: none;
    position: fixed;
    top: 193px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 1000;
    background: $white;

    @include media-breakpoint-down(xl) {
      top: 173px;
    }

    @include media-breakpoint-down(md) {
      display: none !important;
    }

    .mega-menu-categories {
      display: none;

      @include media-breakpoint-down(xl) {
        > .mega-menu-category-wrapper {
          flex: 0 0 20%;
          max-width: 20%;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      &.active {
        display: flex;
      }

        .mega-menu-category {
          padding: 20px;
          border: 1px solid rgba(0,0,0,0.1);
          text-align: center;

          .image-wrapper {
            width: 100%;
            height: 200px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              font-family: 'object-fit: cover;';
            }
          }

          .title {
            margin-top: 15px;
            line-height: 26px;
            text-transform: uppercase;
            font-weight: bold;

            @include media-breakpoint-only(lg) {
              font-size: 14px;
              line-height: 20px;
            }
          }

          .discover {
            color: $green;

            .picto {
              display: inline-block;
              width: auto;
              margin-left: 5px;
              height: 12px;
              @include transition;
            }

            &:hover, &:active, &:focus {
              text-decoration: none;

              .picto {
                margin-left: 10px;
              }
            }
          }
      }
    }
  }

  &.sticky {
    position: fixed;
    top: -150px;
    padding-top: 10px;
    @include transition(.3s);

    &.active {
      top: 0;
    }

    #mega-menu {
      top: 93px !important;
    }

    #logo {
      width: 150px;
    }

    nav.navbar .navbar-collapse {
      padding-top: 0px;

      .navbar-nav.main-menu .nav-link {
        padding-bottom: 5px;
      }

      .navbar-nav.main-sub-menu .nav-item {
        padding-top: 5px;
        padding-bottom: 5px;

        .nav-link {
          line-height: 30px;
        }
      }
    }

    & + main {
      margin-top: 193px;
    }
  }
}